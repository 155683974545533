<template>
	<main class="page-container" id="join-intro">
		<section class="mbrp-section">
			<div class="container">
				<header class="mbrp-header">
					<h2 class="title">통합 회원 가입하기</h2>
				</header>
				<div class="mbrp-body">
					<!-- steps -->
					<div class="steps-wrapper">
						<ol class="steps">
							<li class="steps-segment is-complete">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">1</span>
									</span>
									<div class="steps-content">
										<p class="heading">약관동의</p>
									</div>
								</div>
							</li>
							<li class="steps-segment is-complete">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">2</span>
									</span>
									<div class="steps-content">
										<p class="heading">기업회원 인증</p>
									</div>
								</div>
							</li>
							<li class="steps-segment is-active">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">3</span>
									</span>
									<div class="steps-content">
										<p class="heading">회원정보 입력</p>
									</div>
								</div>
							</li>
							<li class="steps-segment">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">4</span>
									</span>
									<div class="steps-content">
										<p class="heading">회원가입 완료</p>
									</div>
								</div>
							</li>
						</ol>
					</div>
					<!-- //steps -->

					<div class="fp-biz-buttons">
						<button type="button" class="fp-biz-button" :class="{ 'is-active': !isBiz }" @click.prevent="isBiz = false">개인 사업자 가입</button>
						<button type="button" class="fp-biz-button" :class="{ 'is-active': isBiz }" @click.prevent="isBiz = true">법인 사업자 가입</button>
					</div>

					<!-- s: 개인 사업자 가입 -->
					<div v-if="!isBiz" class="mbrp-forms">
						<div>
							<!-- s: 1. 가입정보 -->
							<fieldset class="fieldset-section">
								<div class="fieldset-header">
									<legend class="fieldset-legend">1. 가입정보</legend>
									<span class="fieldset-reference">
										<em class="required">필수</em>
										<span class="text">필수정보</span>
									</span>
								</div>
								<div class="fieldset-content">
									<div class="field field-row is-mobile-row">
										<div class="field-label w160"><label for="">회사유형</label><em class="required">필수</em></div>
										<div class="control field-form">
											<strong class="input-text">개인 사업자</strong>
										</div>
									</div>
									<div class="field field-row">
										<div class="field-label w160"><label for="">아이디</label><em class="required">필수</em></div>
										<div class="control is-grouped field-form">
											<input v-model="lgnId" type="email" class="input" required="true" />
                      <small class="small-text" style="color: red" v-if="!idInit && !isLgnIdCheck">* 이메일 형식이 맞지 않습니다.</small>
											<button type="button" class="button-default is-medium is-secondary is-rounded" @click="lgnIdDuplChk">중복확인</button>
										</div>
									</div>
									<div class="field field-row">
										<div class="field-label w160"><label for="">비밀번호</label><em class="required">필수</em></div>
										<div class="control field-form">
											<div class="input-password-container">
												<div class="input-password-wrap">
													<input v-if="visiblePassword" type="text" class="input" v-model="password" placeholder="영문(대소문자 구분), 숫자, 특수문자 포함 8-16자리로 입력" required="true" />
													<input v-else type="password" class="input" v-model="password" placeholder="영문(대소문자 구분), 숫자, 특수문자 포함 8-16자리로 입력" required="true" />
													<button type="button" class="input-password-button" :class="{ 'is-active': visiblePassword }" @click.prevent="showPassword()">
														<div class="input-password-icons">
															<span><i class="icon-eye-on"></i></span>
															<span><i class="icon-eye-off"></i></span>
														</div>
													</button>
												</div>
												<button type="button" class="button-default is-medium is-secondary is-rounded" @click="ssoPwdVerifyChk">유효성 체크</button>
											</div>
                      <small class="small-text" style="color: red" v-if="!passwordInit && !isPasswordRegx && password.length > 0">* 영문(대소문자 구분), 숫자, 특수문자 포함 8-16자리로 입력해주세요.</small>
										</div>
									</div>
									<div class="field field-row">
										<div class="field-label w160"><label for="">비밀번호 확인</label><em class="required">필수</em></div>
										<div class="control field-form">
											<input v-model="passwordChk" type="password" class="input" placeholder="비밀번호 확인" required="true" />
                      <small class="small-text" style="color: red" v-if="!isPasswordCheck && passwordChk.length > 0">* 비밀번호가 일치하지 않습니다.</small>
										</div>
									</div>
									<div class="field field-row">
										<div class="field-label w160"><label for="">사업자등록번호</label><em class="required">필수</em></div>
										<div class="control field-form">
											<input v-model="bzInfo.brno" type="text" class="input" placeholder="사업자등록번호 입력" required="true" readonly="true" />
										</div>
									</div>
									<div class="field field-row">
										<div class="field-label w160"><label for="">회사명</label></div>
										<div class="control field-form">
											<input v-model="bzInfo.compNm" type="text" class="input" placeholder="회사명 입력" />
										</div>
									</div>
									<div class="field field-row">
										<div class="field-label w160"><label for="">대표자명</label><em class="required">필수</em></div>
										<div class="control field-form">
											<input v-model="bzInfo.ceoNm" type="text" class="input" placeholder="대표자명 입력" readonly="true" />
										</div>
									</div>
									<div class="field field-row">
										<div class="field-label w160"><label for="">개업일자</label><em class="required">필수</em></div>
										<div class="control field-form">
											<input v-model="bzInfo.openDay" type="text" class="input" placeholder="개업일자 입력" readonly="true" />
										</div>
									</div>
								</div>
							</fieldset>
							<!-- e: 1. 가입정보 -->
							<!-- s: 2. 담당자 정보 -->
							<fieldset class="fieldset-section">
								<div class="fieldset-header">
									<legend class="fieldset-legend">2. 담당자 정보</legend>
								</div>
								<div class="fieldset-content">
									<div class="field field-row">
										<div class="field-label w160"><label for="">이름</label><em class="required">필수</em></div>
										<div class="control field-form">
											<input v-model="userNm" type="text" class="input" placeholder="이름 입력" required="true" readonly="true" />
										</div>
									</div>
									<div class="field field-row">
										<div class="field-label w160"><label for="">휴대폰 번호</label><em class="required">필수</em></div>
										<div class="control is-grouped field-form">
											<input v-model="mblNo" type="text" class="input" placeholder="휴대폰번호 입력" required="true" readonly="true" />
											<button type="button" class="button-default is-medium is-secondary is-rounded" @click="startCheckMe">본인인증</button>
										</div>
									</div>
									<!-- <div class="field field-row">
										<div class="field-label w160"><label for="">전화번호</label></div>
										<div class="control field-form">
											<input v-model="telNo" type="text" class="input" placeholder="전화번호 입력" />
										</div>
									</div> -->
									<div class="field field-row">
										<div class="field-label w160"><label for="">이메일 주소</label><em class="required">필수</em></div>
										<div class="control field-form">
											<input v-model="userEmail" type="text" class="input" placeholder="이메일 주소 입력" required="true" />
                      <small class="small-text" style="color: red" v-if="!isEmailCheck && userEmail.length > 0">* 이메일 형식이 맞지 않습니다.</small>
										</div>
									</div>
								</div>
							</fieldset>
							<!-- e: 2. 담당자 정보 -->
							<!-- s: 3. e-러닝 -->
							<fieldset class="fieldset-section">
								<div class="fieldset-header">
									<legend class="fieldset-legend">3. e-러닝</legend>
								</div>
								<div class="fieldset-content">
									<div class="field field-row">
										<div class="field-label w160">
											<label for="felAplyY"
												>온라인 시장<br />
												진출교육(e-러닝)<br />
												수강정보 제공 동의</label
											>
										</div>
										<div class="control field-form">
											<div class="is-grouped">
												<div class="form-radio-list">
													<div class="form-radio">
														<input v-model="felrgEduTknlcrsAplyYn" type="radio" name="felAplyY" id="felAplyY" value="Y"/>
														<label for="felAplyY">예</label>
													</div>
													<div class="form-radio">
														<input v-model="felrgEduTknlcrsAplyYn" type="radio" name="felAplyN" id="felAplyN" value="N"/>
														<label for="felAplyN">아니오</label>
													</div>
												</div>
												<router-link to="/service/freeedudetail" class="button-link-text is-noborder">자세히보기</router-link>
											</div>
											<div class="mbrp-forms-help">
												<p>※ 수강희망하시는 분들의 이름, ID, 전화번호, 이메일 등이 교육 플랫폼(GS ITM Getsmart)에 제공되며 정보제공 동의 후 5일 이내에 포털 '온라인 진출 배움터' 메뉴를 통해 무료교육을 수강하실 수 있습니다.</p>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
							<!-- e: 3. e-러닝 -->

							<!-- s: 4. 마케팅 수신 동의 -->
							<fieldset class="fieldset-section">
								<div class="fieldset-header">
									<legend class="fieldset-legend">4. 마케팅</legend>
								</div>
								<div class="fieldset-content">
									<div class="field field-row">
										<div class="field-label w160">
											<label for="mktAgreY">
												마케팅 수신<br />
												동의여부
											</label>
										</div>
										<div class="control field-form">
											<div class="is-grouped">
												<div class="form-radio-list">
													<div class="form-radio">
														<input type="radio" v-model="mktRcptnAgreYn" name="mktAgreY" id="mktAgreY" value="Y" />
														<label for="mktAgreY">예</label>
													</div>
													<div class="form-radio">
														<input type="radio" v-model="mktRcptnAgreYn" name="mktAgreN" id="mktAgreN" value="N" />
														<label for="mktAgreN">아니오</label>
													</div>
												</div>
												<router-link to="/service/marketingdetail" class="button-link-text is-noborder">자세히보기</router-link>
											</div>
											<div class="mbrp-forms-help">
												<p>※ 마케팅 수신을 동의하시는 분들은 이름, ID, 전화번호, 이메일 등이 이용되며 마케팅 수신 동의한 이후부터 다양한 서비스를 제공받으실 수 있습니다.</p>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
							<!-- e: 4. 마케팅 수신 동의 -->

							<div class="mbrp-cert-desc">
								<p>지원사업 정보 안내</p>
								<ol class="number-list">
									<li>지원사업 추가 정보는 판판대로(<a href="https://fanfandaero.kr/portal/main.do" class="" target="_blank" title="새창열림">https://fanfandaero.kr/portal/main.do</a>)에서 입력 및 수정이 가능합니다.</li>
									<li>추가내용</li>
								</ol>
							</div>

							<div class="buttons text-center">
								<button type="submit" class="button-default is-large is-primary is-rounded" @click="clickNext()">다음 단계</button>
							</div>
						</div>
					</div>
					<!-- e: 개인 사업자 가입 -->
					<!-- s: 법인 사업자 가입 -->
					<div v-else>
						<div class="mbrp-cert-desc is-padding text-center">법인 사업자의 경우 판판대로(<a href="https://fanfandaero.kr/portal/joinUser.do" target="_blank" title="새창열림">https://fanfandaero.kr/portal/main.do</a>)에서 회원가입 가능합니다.</div>
						<div class="buttons text-center">
							<a href="https://fanfandaero.kr/portal/joinUser.do" class="button-default is-large is-primary is-rounded"> 확인(판판대로 이동) </a>
						</div>
					</div>
					<!-- e: 법인 사업자 가입 -->
				</div>
			</div>
		</section>
	</main>
</template>
<script>
import { mapGetters } from 'vuex';
import DropDown from '@/components/common/DropDown';
import {checkEmail, checkPassword} from "@/assets/js/modules/validation";
import {isSuccess, lengthCheck, phoneNumberMask, strToHex, validate, calculateAge, ymdToDate} from "@/assets/js/utils";
import {MUT_SHOW_ALERT} from "@/store/_mut_consts";
import {ACT_CHECK_DUPLICATE_ID, ACT_CHECK_SSO_VERIFY_PWD, ACT_SIGNUP_USER, ACT_CHECK_SSO_DUPLICATE_ID} from "@/store/_act_consts";
import {selfAuth} from "@/assets/js/selfAuth";
import Base64 from "crypto-js/enc-base64";
import SHA512 from "crypto-js/sha512";

export default {
	name: 'JoinIntegratedForm',
	components: {},
	computed: {
		...mapGetters('auth', ['session', 'isAuth', 'checkMeInfo']),
		...mapGetters('common', ['isMobile']),
    ...mapGetters('join', ["bzInfo"])
	},
	watch: {
    // password() {
    //   this.passwordInit = false;
    //   // 숫자 + 문자 9~20자리
    //   this.isPasswordRegx = checkPassword(this.password);
    //   if (this.isPasswordRegx) {
    //     this.isPasswordCheck = this.passwd === this.passwordChk;
    //   } else {
    //     this.isPasswordCheck = true;
    //   }
    // },
    userEmail() {
      this.isEmailCheck = checkEmail(this.userEmail);
    },
    passwordChk() {
      this.isPasswordCheck = this.password === this.passwordChk;
    },
    telNo() {
      this.telNo = phoneNumberMask(this.telNo);
    },
    mblNo() {
      this.mblNo = phoneNumberMask(this.mblNo);
    },
    lgnId() {
      this.idInit = false;
      this.isDuplicateCheck = false;
      this.isLgnIdCheck = checkEmail(this.lgnId);
    },
  },
	data: () => ({
		isBiz: false,
    visiblePassword: false,
    isEmailCheck: false,
    isPasswordRegx: false,
    passwordInit: true,
    isPasswordCheck: false,
    idInit: true,
    isLgnIdCheck: false,
    isCheckMe: false,
    isCert: false,

    // 사용자 정보
    ci: '', // ci 정보
    lgnId:'', // 아이디
    password: '', // 비밀번호
    passwordChk: '', // 비밀번호 확인
    userNm: '', // 이름
    mblNo: '', // 휴대폰 번호
    telNo: '', // 전화번호
    userEmail: '', // 이메일 주소
    felrgEduTknlcrsAplyYn: 'Y',// 이러닝 교육 수강 신청 여부
		mktRcptnAgreYn: 'Y', // 마케팅 수신 동의 여부
	}),
	mounted() {
		if(!this.bzInfo.brno){
			this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
				title: `기업회원 인증 화면으로 돌아갑니다.`,
				yesfunc: () => {
					this.$router.push({ name: 'JoinIntegratedCert' });
				},
			});
		}
	},
	methods: {
		lgnIdDuplChk() {
      if (this.lgnId.trim().length === 0) {
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
          title: `아이디를 입력해주세요.`,
        });
        return;
      }

      if (!this.isLgnIdCheck) {
        this.isLoading = false;
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
          title: '아이디는 이메일 형식으로 입력해주세요.',
          html: true,
        });
        return;
      }

			this.$store.dispatch(`auth/${ACT_CHECK_SSO_DUPLICATE_ID}`, this.lgnId).then((res) => {
        const success = isSuccess(res);
				if(success){
					if(res.ret == 'true'){
						this.isDuplicateCheck = false;
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: `중복 된 아이디입니다.`,
						});
					} else {
						this.isDuplicateCheck = true;
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: `사용 할 수 있는 아이디입니다. `,
						});
					}
				} else {
					this.isDuplicateCheck = false;
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `중복 체크 불가`,
					});
				}
      });
    },
		clickNext() {
      // 0. payload setting
      // 리퀘스트 파라미터 추가
      const payload = {
        userNm: this.userNm, // 이름 : 필수
        lgnId: this.lgnId, // 로그인 아이디 : 필수
        userEmail: this.userEmail, // 이메일 : 필수
        userPasswd: strToHex(SHA512(this.password).toString(Base64)), // 비밀번호 : 필수
				ssoPw: strToHex(this.password), // 비밀번호 Hex : 필수
        mblNo: this.mblNo.replace('-', '').replace('-', ''), // 핸드폰 번호 : 필수
        telNo: this.telNo.replace('-', '').replace('-', ''), // 전화번호
        userPattnCd: '400', // 일반회원 200, 크리에이터 300(사용안함), 통합기업회원 400
				authCode: this.checkMeInfo.name ? SHA512(SHA512(`${this.checkMeInfo.name}_auth`).toString().toUpperCase()).toString().toUpperCase() : '',
				authHCode: this.checkMeInfo.mblNo ? SHA512(SHA512(`${this.checkMeInfo.mblNo.replace('-', '').replace('-', '')}_auth`).toString().toUpperCase()).toString().toUpperCase() : '',
        felrgEduTknlcrsAplyYn: this.felrgEduTknlcrsAplyYn, //무료 e-러닝 교육 희망여부
				mktRcptnAgreYn: this.mktRcptnAgreYn, // 마케팅 수신 동의 여부
				userInflxPtCd: 'PT', // 사용자 유입 경로
        prtlBrno: this.bzInfo.brno, //사업자등록번호
        openDay: this.bzInfo.openDay, //개업일자
        ceoNm: this.bzInfo.ceoNm, //대표명
        compNm: this.bzInfo.compNm, //기업명
        isCompCheck: this.bzInfo.isCompCheck, //사업자정보 검증실행여부
				userCi: this.ci,
      }
      // 1. request parameter validate
      // 필수 파라미터 검증
      const result = validate(this.$store, payload, [
        { key: 'userNm', msg: '이름을 입력해주세요.' },
        { key: 'lgnId', msg: '아이디를 입력해주세요.' },
        { key: 'userPasswd', msg: '비밀번호를 입력해주세요.' },
        { key: 'mblNo', msg: '휴대폰 번호를 입력해주세요.' },
        { key: 'userEmail', msg: '이메일 주소를 입력해주세요.' }
      ]);

      // validation complete
      if (result) {
        // 2-1. bad param --> msg alert
				//this.isCheckMe = true; //test 임시
        if (!this.checkClientParams()) {
          return;
        }
        // 2-2. clean param --> call sign-up func
        this.$store.dispatch(`auth/${ACT_SIGNUP_USER}`, payload).then((res) => {
          if (isSuccess(res)) {
            this.$store.dispatch("join/updateUserNm", this.userNm)
            this.$router.push({ name: 'JoinIntegratedComplete' });
          } else {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: `회원가입이 실패하였습니다.`,
						});
					}
        });
      }
		},
		checkClientParams() {
      let msg = '';
      let result = false;

      // 아이디 포맷 체크
      if (!this.isLgnIdCheck) {
        msg = '이메일 형식의 아이디를 입력해주세요.';
      } else if (!this.isDuplicateCheck) {
        msg = '아이디 중복확인을 해주세요.';
      } else if (!this.isPasswordRegx) {
        msg = '비밀번호는 영문(대소문자 구분), 숫자, 특수문자 포함<br>8-16자리로 입력해주세요.';
      } else if (!this.isPasswordCheck) {
        msg = '비밀번호가 일치하지 않습니다.';
      } else if (!this.isEmailCheck){
				msg = '이메일 형식의 이메일을 입력해주세요.';
			} else if (!this.isCheckMe){
				msg = '본인 인증을 진행해주세요.';
			} else {
        result = true;
      }

      if (!result) {
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
          title: msg,
          html: true,
        });
      }
      return result;
    },
		showPassword() {
			this.visiblePassword = !this.visiblePassword;
		},    
    startCheckMe() {
      selfAuth(this.$store, this);
    },
    checkMeInfoSuccess() {
      // this.$store.dispatch(`auth/${ACT_GET_USER_FIND_ID_LIST}`, {
      //   userNm: this.checkMeInfo.name,
      //   mblNo: this.checkMeInfo.mblNo
      // }).then(res => {
      //   if(lengthCheck(res)){
      //     this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '이미 가입된 아이디가 있습니다.',});
      //     this.$router.push({name: 'Login'});
      //   }else{
      //     this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '본인인증이 완료되었습니다.'});
      //     this.userNm = this.checkMeInfo.name
      //     this.mblNo = this.checkMeInfo.mblNo
      //     this.ci = this.checkMeInfo.ci
      //   }
      // }).catch(e => {
      //   console.error(e);
      // });
			const brDt = ymdToDate(this.checkMeInfo.custBrdt);
			const age = calculateAge(brDt);
			if (age < 14){
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '만 14세 미만의 경우 회원 가입을 할 수 없습니다.',});
			} else {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '본인인증이 완료되었습니다.'});
				this.userNm = this.checkMeInfo.name;
				this.mblNo = this.checkMeInfo.mblNo;
				this.ci = this.checkMeInfo.ci;
			}
    },
		ssoPwdVerifyChk() {
      if (this.password.trim().length === 0) {
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
          title: `비밀번호를 입력해주세요.`,
        });
        return;
      }

      this.$store.dispatch(`auth/${ACT_CHECK_SSO_VERIFY_PWD}`, strToHex(this.password)).then((res) => {
        const success = isSuccess(res);
				if(success){
					if(res.ret === ''){
						this.isPasswordRegx = true;
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: `사용가능한 비밀번호 입니다.`,
						});
					} else {
						this.isPasswordRegx = false;
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: res.ret,
						});
					}
				} else {
					this.isPasswordRegx = false;
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `비밀번호 체크 불가`,
					});
				}
      });
    },
	},
	destroyed() {},
};
</script>
